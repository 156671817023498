import styles from './Dropdown.module.scss';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Dropdown as DD } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import ReactSearchBox from 'react-search-box';

const Dropdown = ({
  label,
  selected,
  options,
  required,
  selectedHandler,
  className,
  search,
}) => {
  const [list, setList] = useState(options);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    setList(options);
    if (search) {
      const searchList = options.map((obj) => {
        return {
          key: obj,
          value: obj,
        };
      });
      setList(searchList);
    }
  }, [options]);

  useEffect(() => {
    if (dropdownOpen) {
      var xpath = `//div[contains(@class, 'dropdown-menu') and contains(@class, 'show')]/button[text()='${selected.toLowerCase()}']`;
      var el = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;
      if (el)
        el.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'start',
        });
    }
  }, [dropdownOpen]);

  const updateSelection = (event) =>
    selectedHandler(search ? event : event.target.innerText);

  return (
    <div className={`${styles.container} ${className}`}>
      {label && (
        <div className={styles.label}>
          {label}
          {required && <sup>*</sup>}
        </div>
      )}
      {search && (
        <ReactSearchBox
          placeholder={selected}
          data={list}
          inputBorderColor={'transparent'}
          fuseConfigs={{
            includeMatches: true,
            findAllMatches: true,
            ignoreLocation: true,
          }}
          onChange={updateSelection}
        />
      )}
      {!search && (
        <DD className={styles.dropdown} isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            style={{
              width: '100%',
              textAlign: 'left',
              position: 'relative',
              backgroundColor: '#f4f4f7',
              color: 'black',
              border: 'none',
              lineHeight: 2.5,
            }}>
            {selected}

            <img
              className={styles.arrow}
              src={dropdownOpen ? 'arrow-up.png' : 'arrow-down.png'}
            />
          </DropdownToggle>
          <DropdownMenu
            className={styles.menu}
            style={{
              width: '100%',
              backgroundColor: '#e9e9f0',
              maxHeight: '12rem',
              overflowY: 'scroll',
            }}>
            {list.map((o) => (
              <DropdownItem
                key={o}
                onClick={updateSelection}
                style={{ backgroundColor: '#e9e9f0', lineHeight: 2.5 }}>
                {o}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DD>
      )}
    </div>
  );
};

export default Dropdown;

import { SET_ADMIN_TOKEN } from '../constants';

const initialState = JSON.parse(localStorage.getItem('app')) || {
  MRN: '',
  adminToken: '',
};

const updateLocalStorage = (state) => {
  localStorage.setItem('app', JSON.stringify(state));
  return state;
};

export const app = (state = initialState, action = {}) =>
  updateLocalStorage(reducer(state, action));

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ADMIN_TOKEN:
      return { ...state, adminToken: action.payload };
    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react';
import styles from './LocationSelect.module.scss';
import { Button, NarrowContainer, P, H4 } from '../../styled/components';
import { useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getAdminAuthToken } from '../../Shared';
import {
  listLocalHealthDistricts,
  listHospitals,
  listWards,
} from '../../graphql/queries';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import Notification from '../../components/shared/notifications/Notification';
import {
  setHospital,
  setDistrict,
  setWard,
  setWardId,
  setWardList,
} from '../../store/actions/location';
import { useSelector, useDispatch } from 'react-redux';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
const override = css`
  position: fixed;
  left: 50%;
  top: 50%;
`;

const LocationSelect = () => {
  const history = useHistory();
  const [fail, setFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const emptyList = [];

  const [locationData, setLocationData] = useState({});

  const [district, setDistrictLocal] = useState('Select');
  const [districtList, setDistrictList] = useState(emptyList);
  const lastDistrict = useSelector((state) => state.location.district);

  const [hospital, setHospitalLocal] = useState('Select');
  const [hospitalList, setHospitalList] = useState(emptyList);
  const lastHospital = useSelector((state) => state.location.hospital);

  const [ward, setWardLocal] = useState('Select');
  const [wardList, setWardListLocal] = useState(emptyList);
  const lastWard = useSelector((state) => state.location.ward);

  const dispatch = useDispatch();

  useEffect(async () => {
    let d = getHealthDistricts();
    let h = getHospitals();
    let w = getWards();
    setLoading(true);
    const [districts, hospitals, wards] = await Promise.all([d, h, w]);
    setLoading(false);
    if (d && h && w) {
      setLocationData({ districts, hospitals, wards });
      setDistrictList(districts);
    }
    setDistrictLocal(lastDistrict);
    setHospitalLocal(lastHospital);
    setWardLocal(lastWard);
  }, []);

  useEffect(() => {
    if (district !== 'Select' && locationData.districts) {
      const matchedDistrict = districtList.filter((d) => d.lcName === district);
      if (!matchedDistrict.length) return;
      const districtId = matchedDistrict[0].lcID;
      setHospitalList(
        locationData.hospitals.filter((h) => h.lcParent === districtId)
      );
    }
  }, [district]);

  useEffect(() => {
    if (hospital !== 'Select' && locationData.hospitals) {
      const matchedHospital = hospitalList.filter((d) => d.lcName === hospital);
      if (!matchedHospital.length) return;
      const hospitalId = matchedHospital[0].lcID;
      setWardListLocal(
        locationData.wards.filter((h) => h.lcParent === hospitalId)
      );
    }
  }, [hospital]);

  const getHealthDistricts = async () => {
    try {
      const districtsRes = await API.graphql(
        graphqlOperation(listLocalHealthDistricts),
        { Authorization: getAdminAuthToken() }
      );
      return districtsRes.data.listLocalHealthDistricts;
    } catch (err) {
      setFail(true);
      console.error(err);
      return false;
    }
  };
  const getHospitals = async () => {
    try {
      const hospitalRes = await API.graphql(graphqlOperation(listHospitals), {
        Authorization: getAdminAuthToken(),
      });
      return hospitalRes.data.listHospitals;
    } catch (err) {
      setFail(true);
      console.error(err);
      return false;
    }
  };
  const getWards = async () => {
    const wardRes = await API.graphql(graphqlOperation(listWards), {
      Authorization: getAdminAuthToken(),
    });
    try {
      return wardRes.data.listWards;
    } catch (err) {
      setFail(true);
      console.error(err);
      return false;
    }
  };

  const nextClicked = async () => {
    const wardId = locationData.wards.filter((w) => w.lcName === ward)[0].lcID;
    dispatch(setWardList(wardList));
    dispatch(setDistrict(district));
    dispatch(setHospital(hospital));
    dispatch(setWard(ward));
    dispatch(setWardId(wardId));

    history.push('/dashboard');
  };

  const updateDistrict = (event) => {
    if (district !== event) {
      setHospitalLocal('Select');
      setWardLocal('Select');
      setDistrictLocal(event);
    }
  };
  const updateHospital = (event) => {
    if (hospital !== event) setWardLocal('Select');
    setHospitalLocal(event);
  };
  const updateWard = (event) => {
    setWardLocal(event);
  };

  const invalidLocation = (locationList, name) => {
    if (!locationList.length) return true;
    const valid = locationList.filter((l) => l.lcName === name);
    return !valid.length;
  };
  const disableButton =
    invalidLocation(districtList, district) ||
    invalidLocation(hospitalList, hospital) ||
    invalidLocation(wardList, ward);

  return (
    <NarrowContainer>
      {fail && (
        <Notification
          warning
          message={
            'Sorry, locations could not be fetched. Please try again later'
          }
        />
      )}
      <div className={styles.container}>
        <div>
          <H4>Choose Location</H4>
          <P style={{ marginTop: '1rem' }}>
            To set up device, choose your ward by first filtering on your LHD
            and Hospital
          </P>
          <Dropdown
            label={'Local Health District'}
            selected={district}
            options={districtList.map((d) => d.lcName)}
            selectedHandler={updateDistrict}
            required={true}
          />
          <Dropdown
            label={'Hospital'}
            selected={hospital}
            options={hospitalList.map((h) => h.lcName)}
            selectedHandler={updateHospital}
            required={true}
          />
          <Dropdown
            label={'Ward'}
            selected={ward}
            options={wardList.map((w) => w.lcName)}
            selectedHandler={updateWard}
            required={true}
          />
        </div>
        <Button
          style={{ position: 'absolute', bottom: '2rem' }}
          onClick={nextClicked}
          isDisabled={disableButton}
          disabled={disableButton}>
          Next
        </Button>
      </div>
      <FadeLoader loading={loading} css={override} />
    </NarrowContainer>
  );
};

export default LocationSelect;

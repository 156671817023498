import React, { useState, useEffect } from 'react';
import styles from './NewPatient.module.scss';
import {
  Button,
  OutlinedButton,
  Container,
  P,
  H4,
  H5,
  Link,
} from '../../styled/components';
import Switch from '../../components/shared/switch/Switch';
import InputField from '../../components/shared/input/InputField';
import MultiInputRow from '../../components/shared/multiInputRow/MultiInputRow';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import { useHistory } from 'react-router-dom';
import {
  createPatient,
  createSession,
  createPatientContact,
} from '../../graphql/mutations';
import {
  getAdminAuthToken,
  makeTimes,
  getTimestampToTheHour,
  dateToHourString,
} from '../../Shared';
import { API, graphqlOperation } from 'aws-amplify';
import Notification from '../../components/shared/notifications/Notification';
import { phone } from 'phone';
import { useSelector } from 'react-redux';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
const override = css`
  position: fixed;
  left: 50%;
  top: 50%;
`;

const NewPatient = () => {
  const history = useHistory();
  const empty = [{ email: '', phoneNumber: '', name: '' }];
  const [MRN, setMRN] = useState('');
  const [visitors, setVisitors] = useState(empty);
  const [patientName, setPatientName] = useState('');
  const [startTime, setStartTime] = useState('Select');
  const [endTime, setEndTime] = useState('Select');
  const [autoAnswer, setAutoAnswer] = useState(false);
  const [fail, setFail] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const wardId = useSelector((state) => state.location.wardId);
  const startTimes = makeTimes();
  const endTimes = ['Indefinite', ...makeTimes()];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let now = new Date();
    let time = dateToHourString(now);
    setStartTime(time);
    setEndTime(time);
  }, []);

  const updateStartTime = (event) => {
    setStartTime(event);
  };

  const updateEndTime = (event) => {
    setEndTime(event);
  };

  const updateMRN = (event) => {
    setMRN(event.target.value);
  };

  const updatePatientName = (event) => {
    setPatientName(event.target.value);
  };

  const createPatientReq = async () => {
    const fName = patientName.split(' ')[0] || '';
    const lName = patientName.split(' ')[1] || '';
    try {
      const patientReq = {
        ptFirstName: fName,
        ptLastName: lName,
        ptMRN: MRN,
        ptArchived: false,
        lcID: wardId,
        ptAutoAnswer: autoAnswer,
      };
      const createResponse = await API.graphql(
        graphqlOperation(createPatient, patientReq),
        { Authorization: getAdminAuthToken() }
      );
      console.log('Create patient success: ', createResponse);
      return createResponse.data.createPatient.ptID;
    } catch (err) {
      console.error(err);
      return -1;
    }
  };

  const createSessionReq = async (patientId) => {
    // ssEnd will be Nullable in future
    const ssStart = getTimestampToTheHour(startTime);
    const ssEnd = getTimestampToTheHour(endTime) || 0;
    const indefinite = ssEnd ? false : true;

    const session = {
      ptID: patientId,
      ssStart: ssStart,
      ssEnd: ssEnd,
      ssIndefinite: indefinite,
      ssArchived: false,
      ssAutoAnswer: autoAnswer,
    };
    try {
      const createResponse = await API.graphql(
        graphqlOperation(createSession, session),
        { Authorization: getAdminAuthToken() }
      );
      console.log(
        'Create sessions success: ',
        createResponse.data.createSession
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const inviteVisitors = async (patientId) => {
    try {
      var filtered = visitors.filter(function (v) {
        return !(v.email === '' && v.phoneNumber === '' && v.name === '');
      });
      await Promise.all(
        filtered.map(async (v) => {
          const contactInfo = {
            ptID: patientId,
            pcName: v.name || '',
            pcPhone: v.phoneNumber || '',
            pcEmail: v.email || '',
          };
          const contactData = await API.graphql(
            graphqlOperation(createPatientContact, contactInfo),
            { Authorization: getAdminAuthToken() }
          );
          console.log(
            'created contact ',
            contactData.data.createPatientContact
          );
        })
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const nextClicked = async () => {
    if (disableNext()) return;
    setLoading(true);
    const pid = await createPatientReq();
    if (pid !== -1) {
      if ((await createSessionReq(pid)) && (await inviteVisitors(pid))) {
        history.push({ pathname: '/dashboard', from: 'newPatient' });
      }
    }
    setLoading(false);
    setFailMessage('Setup was unsuccessful');
    setFail(true);
    window.scrollTo(0, 0);
  };

  const cancelClicked = () => {
    history.push('/loginpatient');
  };

  const updateVisitorEmail = (event, i) => {
    let updated = [...visitors];
    updated[i].email = event.target.value;
    setVisitors(updated);
  };

  const updateVisitorPhone = (event, i) => {
    let updated = [...visitors];
    let number = event.target.value === '' ? '' : '+' + event.target.value;
    updated[i].phoneNumber =
      event.target.value === '' ? '' : phone(number).phoneNumber;
    setVisitors(updated);
  };

  const updateVisitorName = (event, i) => {
    let updated = [...visitors];
    updated[i].name = event.target.value;
    setVisitors(updated);
  };

  const addVisitorRow = () => {
    let updated = [...visitors];
    updated.push(empty[0]);
    setVisitors(updated);
  };

  const invalid = (msg) => {
    setFailMessage(msg);
    setFail(true);
    window.scrollTo(0, 0);
  };

  const disableNext = () => {
    if (startTime === 'Select' || endTime === 'Select') {
      invalid('Please select visiting hours');
      return true;
    }
    let isDisabled = !MRN || !patientName;
    if (isDisabled) {
      invalid('MRN and Patient Name are required');
      return true;
    }
    var filtered = visitors.filter(function (v) {
      return !(v.email === '' && v.phoneNumber === '' && v.name === '');
    });
    filtered.forEach((v) => {
      if (v.email !== '' || v.phoneNumber !== '' || v.name !== '') {
        isDisabled =
          isDisabled ||
          (v.email === '' && v.phoneNumber === '') ||
          v.name === '';
        if (isDisabled)
          invalid(
            'Visitor information requires name and one of email or phone number'
          );
        if (v.phoneNumber !== '') {
          isDisabled = isDisabled || !phone(v.phoneNumber).isValid;
          if (!phone(v.phoneNumber).isValid)
            invalid(
              'Incorrect number, please check the number the number is formatted properly eg +61 XXX XXX XXX'
            );
        }
      }
    });
    return isDisabled;
  };

  return (
    <Container className={styles.container}>
      {fail && <Notification warning message={failMessage} />}
      <H4 className={styles.mrgnSml}>Create New Patient</H4>
      <P className={styles.mrgn}>
        Please enter the patient’s MRN and name (last name, given name)
      </P>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <InputField
          style={{ marginRight: '0.3rem' }}
          inputHandler={updateMRN}
          label='MRN'
          placeholder="Patient's MRN"
          required
        />
        <InputField
          inputHandler={updatePatientName}
          label="Patient's Name"
          placeholder='E.g. Barry Smith'
          required
        />
      </div>
      <H5>Enable Auto-Answer for incoming calls</H5>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <P style={{ marginRight: '2rem' }}>
          When the switch is green, Auto-Answer is ON and all visitor calls made
          to the patient’s device during the chosen visiting hours will be
          accepted automatically. This can be turned on and off at anytime.
        </P>
        <Switch checked={autoAnswer} onChange={(e) => setAutoAnswer(e)} />
      </div>
      <H5 className={styles.mrgnSml}>Add Visiting Hours</H5>
      <P className={styles.mrgn}>
        Virtual Visiting Hours are the start and end time that a patient can
        receive calls to this device. The start and end time you set will be
        forwarded to the patients nominated visitors so they know when they’re
        able to call.
      </P>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <Dropdown
          label={'Visiting hours - Start time'}
          selected={startTime}
          options={startTimes}
          selectedHandler={updateStartTime}
          required={true}
        />
        <div style={{ width: '1rem' }}></div>
        <Dropdown
          label={'Visiting hours - End time'}
          selected={endTime}
          options={endTimes}
          selectedHandler={updateEndTime}
          required={true}
        />
      </div>
      <H5 className={styles.mrgnSml}>Add Nominated Visitors</H5>
      <P className={styles.mrgn}>
        Please enter the visitor’s name and their email address and/or phone
        number to add them as a nominated visitor. An invite will be sent to
        them via SMS and/or email once you click “Saves changes &amp; send
        invitations.”
      </P>
      {visitors.map((v, i) => (
        <MultiInputRow
          key={'row' + i}
          index={i}
          emailHandler={updateVisitorEmail}
          phoneHandler={updateVisitorPhone}
          nameHandler={updateVisitorName}
          showLabels={i === 0}
        />
      ))}
      <Link bold className={styles.mrgn} onClick={addVisitorRow}>
        + Add another contact
      </Link>
      <div className={styles.flex}>
        <Button onClick={() => nextClicked()}>
          Confirm Details &amp; Send Invitations
        </Button>
        <OutlinedButton
          style={{ width: '30%' }}
          className={styles.cancel}
          onClick={() => cancelClicked()}>
          Cancel
        </OutlinedButton>
      </div>
      <FadeLoader loading={loading} css={override} />
    </Container>
  );
};

export default NewPatient;

import React, { useState, useEffect } from 'react';
import styles from './ManagePatient.module.scss';
import {
  Button,
  OutlinedButton,
  Container,
  P,
  H4,
  H5,
  Link,
} from '../../styled/components';
import Switch from '../../components/shared/switch/Switch';
import InputField from '../../components/shared/input/InputField';
import MultiInputRow from '../../components/shared/multiInputRow/MultiInputRow';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import { useHistory, useLocation } from 'react-router-dom';
import {
  updatePatient,
  updateSession,
  updatePatientContact,
  createPatientContact,
} from '../../graphql/mutations';
import {
  getAdminAuthToken,
  makeTimes,
  unixToString,
  getTimestampToTheHour,
} from '../../Shared';
import { API, graphqlOperation } from 'aws-amplify';
import Notification from '../../components/shared/notifications/Notification';
import VisitorInfoRow from '../../components/shared/visitorInfoRow/VisitorInfoRow';
import Modal from '../../components/shared/modal/Modal';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
import { phone } from 'phone';
import { useSelector } from 'react-redux';

const ManagePatient = () => {
  const history = useHistory();
  const location = useLocation();
  const empty = [{ email: '', phoneNumber: '', name: '' }];
  const [MRN, setMRN] = useState('');
  const [visitors, setVisitors] = useState(empty);
  const [newVisitors, setNewVisitors] = useState(empty);
  const [patientName, setPatientName] = useState('');
  const [startTime, setStartTime] = useState('Select');
  const [endTime, setEndTime] = useState('Select');
  const [autoAnswer, setAutoAnswer] = useState(false);
  const [editName, setEditName] = useState(false);
  const [fail, setFail] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const [archiveModal, setArchiveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const wardId = useSelector((state) => state.location.wardId);

  useEffect(() => {
    setFields();
  }, [location]);

  const startTimes = makeTimes();
  const endTimes = ['Indefinite', ...makeTimes()];

  const override = css`
    position: fixed;
    left: 50%;
    top: 50%;
  `;

  const createList = (visitors) => {
    if (visitors) {
      visitors.map((obj) => {
        obj.edit = false;
        obj.archived = obj.pcArchived || false;

        return obj;
      });
      return visitors;
    }
    return [];
  };

  const setFields = () => {
    setMRN(location.state.patient.ptMRN);
    setPatientName(
      location.state.patient.ptFirstName +
        ' ' +
        location.state.patient.ptLastName
    );
    let patientContacts = location.state.patient.patientContacts;
    patientContacts = patientContacts?.map((c) => {
      return {
        email: c.pcEmail,
        phoneNumber: c.pcPhone,
        name: c.pcName,
        pcID: c.pcID,
      };
    });
    let contacts = createList(patientContacts);
    setVisitors(contacts);
    setAutoAnswer(location.state.patient.ptAutoAnswer);
    setTimes();
  };

  const setTimes = () => {
    let start;
    let end;
    if (location.state.session) {
      let seshDate = new Date(location.state.session?.ssStart * 1000);
      start = unixToString(seshDate);
      if (location.state.session?.ssIndefinite) {
        end = 'Indefinite';
      } else {
        seshDate = new Date(location.state.session?.ssEnd * 1000);
        end = unixToString(seshDate);
      }
    } else {
      start = 'Select';
      end = 'Select';
    }
    setStartTime(start);
    setEndTime(end);
  };

  const updateStartTime = (event) => {
    setStartTime(event);
  };

  const updateEndTime = (event) => {
    setEndTime(event);
  };

  const updateMRN = (event) => {
    setMRN(event.target.value);
  };

  const updatePatientName = (event) => {
    setPatientName(event.target.value);
  };

  const updatePatientReq = async (patientReq) => {
    try {
      const updateResponse = await API.graphql(
        graphqlOperation(updatePatient, patientReq),
        { Authorization: getAdminAuthToken() }
      );
      console.log('Update patient success: ', updateResponse);
      return updateResponse.data.updatePatient.ptID;
    } catch (err) {
      console.error(err);
      return -1;
    }
  };

  const updateSessionReq = async (patientId) => {
    // ssEnd will be Nullable in future
    const ssStart = getTimestampToTheHour(startTime);
    const ssEnd = getTimestampToTheHour(endTime) || 0;
    const indefinite = ssEnd ? false : true;

    const session = {
      ptID: patientId,
      ssStart: ssStart,
      ssEnd: ssEnd,
      ssIndefinite: indefinite,
      ssArchived: false,
    };
    try {
      const createResponse = await API.graphql(
        graphqlOperation(updateSession, session),
        { Authorization: getAdminAuthToken() }
      );
      console.log(
        'Update sessions success: ',
        createResponse.data.createSession
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const updateContactReq = async () => {
    try {
      await Promise.all(
        visitors.map(async (v) => {
          if (v.edit || v.archived) {
            const contactInfo = {
              ptID: location.state.patient.ptID,
              pcName: v.name || '',
              pcPhone: v.phoneNumber || '',
              pcEmail: v.email || '',
              pcID: v.pcID || '',
              pcArchived: v.archived,
            };
            const res = await API.graphql(
              graphqlOperation(updatePatientContact, contactInfo),
              { Authorization: getAdminAuthToken() }
            );
            console.log('Update contact success: ', res.data);
          }
        })
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const addNewContactsReq = async () => {
    var filtered = newVisitors.filter(function (v) {
      return !(v.email === '' && v.phoneNumber === '' && v.name === '');
    });
    try {
      await Promise.all(
        filtered.map(async (v) => {
          const contactInfo = {
            ptID: location.state.patient.ptID,
            pcName: v.name || '',
            pcPhone: v.phoneNumber || '',
            pcEmail: v.email || '',
          };
          const res = await API.graphql(
            graphqlOperation(createPatientContact, contactInfo),
            { Authorization: getAdminAuthToken() }
          );
          console.log('add contact success: ', res.data);
        })
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const nextClicked = async () => {
    if (disableNext()) return;
    setLoading(true);
    const fName = patientName.split(' ')[0] || '';
    const lName = patientName.split(' ')[1] || '';

    const patientReq = {
      ptID: location.state.patient.ptID,
      ptFirstName: fName,
      ptLastName: lName,
      ptMRN: MRN,
      ptArchived: false,
      lcID: wardId,
      ptAutoAnswer: autoAnswer,
    };
    const pid = await updatePatientReq(patientReq);
    if (pid !== -1) {
      if (await updateSessionReq(pid)) {
        if (await updateContactReq()) {
          if (await addNewContactsReq())
            history.push({ pathname: '/dashboard', from: 'managepatient' });
        }
      }
    }
    setFailMessage('Updates were unsuccessful');
    setLoading(false);
    setFail(true);
    window.scrollTo(0, 0);
  };

  const cancelClicked = () => {
    history.push('/dashboard');
  };

  const updateNewVisitorEmail = (event, i) => {
    let updated = [...newVisitors];
    updated[i].email = event.target.value;
    setNewVisitors(updated);
  };

  const updateNewVisitorPhone = (event, i) => {
    let updated = [...newVisitors];
    let number = event.target.value === '' ? '' : '+' + event.target.value;
    updated[i].phoneNumber =
      event.target.value === '' ? '' : phone(number).phoneNumber;
    setNewVisitors(updated);
  };

  const updateNewVisitorName = (event, i) => {
    let updated = [...newVisitors];
    updated[i].name = event.target.value;
    setNewVisitors(updated);
  };

  const updateVisitorEmail = (event, i) => {
    let updated = [...visitors];
    updated[i].email = event.target.value;
    setVisitors(updated);
  };

  const updateVisitorPhone = (event, i) => {
    let updated = [...visitors];
    let number = event.target.value === '' ? '' : '+' + event.target.value;
    updated[i].phoneNumber =
      event.target.value === '' ? '' : phone(number).phoneNumber;
    setVisitors(updated);
  };

  const updateVisitorName = (event, i) => {
    let updated = [...visitors];
    updated[i].name = event.target.value;
    setVisitors(updated);
  };

  const addVisitorRow = () => {
    let updated = [...visitors];
    updated.push(empty[0]);
    setVisitors(updated);
  };

  const archivePatientClicked = async () => {
    if (location.state.patient.ptArchived) {
      const fName = patientName.split(' ')[0] || '';
      const lName = patientName.split(' ')[1] || '';
      const patientReq = {
        ptID: location.state.patient.ptID,
        ptFirstName: fName,
        ptLastName: lName,
        ptMRN: MRN,
        ptArchived: false,
        lcID: wardId,
      };
      const pid = await updatePatientReq(patientReq);
      if (pid > 0) history.push('/dashboard');
      else setFail(true);
    } else setArchiveModal(true);
  };

  const confirmArchive = async () => {
    const fName = patientName.split(' ')[0] || '';
    const lName = patientName.split(' ')[1] || '';

    const patientReq = {
      ptID: location.state.patient.ptID,
      ptFirstName: fName,
      ptLastName: lName,
      ptMRN: MRN,
      ptArchived: true,
      lcID: wardId,
    };
    const pid = await updatePatientReq(patientReq);
    if (pid > 0) history.push({ pathname: '/confirm', state: location.state });
    else {
      setArchiveModal(false);
      setFail(true);
    }
  };

  const editClicked = (event, i) => {
    let updated = [...visitors];
    updated[i].edit = true;
    setVisitors(updated);
  };

  const removeClicked = (event, i) => {
    let updated = [...visitors];
    updated[i].archived = true;
    setVisitors(updated);
  };

  const disableNext = () => {
    let isDisabled = false;
    var filtered = newVisitors.filter(function (v) {
      return !(v.email === '' && v.phoneNumber === '' && v.name === '');
    });
    filtered.forEach((v) => {
      if (v.email !== '' || v.phoneNumber !== '' || v.name !== '') {
        isDisabled =
          isDisabled ||
          (v.email === '' && v.phoneNumber === '') ||
          v.name === '';
        if (isDisabled)
          invalid(
            'Visitor information requires name and one of email or phone number'
          );
        if (v.phoneNumber !== '') {
          isDisabled = isDisabled || !phone(v.phoneNumber).isValid;
          if (!phone(v.phoneNumber).isValid)
            invalid(
              'Incorrect number, please check the number the number is formatted properly eg +61 XXX XXX XXX'
            );
        }
      }
    });
    return isDisabled;
  };

  const invalid = (msg) => {
    setFailMessage(msg);
    setFail(true);
    window.scrollTo(0, 0);
  };

  return (
    <Container className={styles.container}>
      {fail && <Notification warning message={failMessage} />}
      {archiveModal && (
        <Modal
          buttonLabel1={'Archive Patient Visitation File'}
          handleButton1={confirmArchive}
          buttonLabel2={'Cancel'}
          handleButton2={() => setArchiveModal(false)}
          copy={`Are you sure you want to archive ${patientName} Patient Visitation file?`}
        />
      )}
      <H4 className={styles.mrgn}>Manage Patient</H4>
      <P className={styles.mrgn}>Patient Details</P>
      <div
        className={`${styles.flex} ${styles.mrgn}`}
        style={{ alignItems: 'flex-end' }}>
        <InputField
          style={{ marginRight: '0.3rem' }}
          disabled={!editName}
          inputHandler={updateMRN}
          value={MRN}
          label='MRN'
          placeholder="Patient's MRN"
          required
        />
        <InputField
          style={{ marginRight: '0.3rem' }}
          inputHandler={updatePatientName}
          disabled={!editName}
          label="Patient's Name"
          value={patientName}
          placeholder='E.g. Barry Smith'
          required
        />
        {/* {!editName && <button className={styles.editBtn} onClick={()=>{setEditName(true);}}>Edit</button>} */}
      </div>
      <H5>Enable Auto-Answer for incoming calls</H5>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <P style={{ marginRight: '2rem' }}>
          When the switch is green, Auto-Answer is ON and all visitor calls made
          to the patient’s device during the chosen visiting hours will be
          accepted automatically. This can be turned on and off at anytime.
        </P>
        <Switch checked={autoAnswer} onChange={(e) => setAutoAnswer(e)} />
      </div>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <Button
          onClick={() => {
            history.push({ pathname: '/transfer', state: location.state });
          }}>
          Transfer Ward
        </Button>
        <Button
          style={{ backgroundColor: '#c53040', marginLeft: '1rem' }}
          onClick={archivePatientClicked}>
          {location.state.patient.ptArchived ? 'Unarchive' : 'Archive'} Patient
          Visitation File
        </Button>
      </div>
      <H5 className={styles.mrgnSml}>Edit Visiting Hours</H5>
      <P className={styles.mrgn}>
        Virtual Visiting Hours are the start and end time that a patient can
        receive calls to this device. The start and end time you set will be
        forwarded to the patients nominated visitors so they know when they’re
        able to call.
      </P>
      <div className={`${styles.flex} ${styles.mrgn}`}>
        <Dropdown
          label={'Visiting hours - Start time'}
          selected={startTime}
          options={startTimes}
          selectedHandler={updateStartTime}
          required={true}
        />
        <div style={{ width: '1rem' }}></div>
        <Dropdown
          label={'Visiting hours - End time'}
          selected={endTime}
          options={endTimes}
          selectedHandler={updateEndTime}
          required={true}
        />
      </div>
      <H5 className={styles.mrgnSml}>Add Nominated Visitors</H5>
      <P className={styles.mrgn}>
        Please enter the visitor’s name and their email address and/or phone
        number to add them as a nominated visitor. An invite will be sent to
        them via SMS and/or email once you click “Saves changes &amp; send
        invitations.”
      </P>
      {newVisitors.map((v, i) => (
        <MultiInputRow
          key={'row' + i}
          index={i}
          emailHandler={updateNewVisitorEmail}
          phoneHandler={updateNewVisitorPhone}
          nameHandler={updateNewVisitorName}
          showLabels={i === 0}
        />
      ))}
      <Link bold className={styles.mrgn} onClick={addVisitorRow}>
        + Add another contact
      </Link>
      {visitors && (
        <>
          <H5 className={styles.mrgnSml}>
            Managing Existing Nominated Visitor List
          </H5>
          <P className={styles.mrgn}>
            Remove an existing nominated visitor by clicking on “Remove” on the
            corresponding row or edit their contact details by clicking ‘Edit.'
          </P>
          {visitors.map(
            (v, i) =>
              !v.archived && (
                <div style={{ width: '100%' }} key={i + 'row'}>
                  {v.edit ? (
                    <MultiInputRow
                      index={i}
                      email={v.email}
                      phoneNumber={v.phoneNumber}
                      name={v.name}
                      emailHandler={updateVisitorEmail}
                      phoneHandler={updateVisitorPhone}
                      nameHandler={updateVisitorName}
                    />
                  ) : (
                    <VisitorInfoRow
                      index={i}
                      name={v.name}
                      email={v.email}
                      phoneNumber={v.phoneNumber}
                      editHandler={editClicked}
                      removeHandler={removeClicked}
                    />
                  )}
                </div>
              )
          )}
        </>
      )}
      <div className={styles.flex} style={{ marginTop: '2rem' }}>
        <Button onClick={() => nextClicked()}>
          Confirm Details &amp; Send Invitations
        </Button>
        <OutlinedButton
          style={{ width: '30%' }}
          className={styles.cancel}
          onClick={() => cancelClicked()}>
          Cancel
        </OutlinedButton>
      </div>
      <FadeLoader loading={loading} color={'#000000'} css={override} />
    </Container>
  );
};

export default ManagePatient;

import React, { useState, useEffect } from 'react';
import styles from './Transfer.module.scss';
import {
  Button,
  NarrowContainer,
  P,
  H4,
  OutlinedButton,
} from '../../styled/components';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getAdminAuthToken } from '../../Shared';
import { updatePatient } from '../../graphql/mutations';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import Notification from '../../components/shared/notifications/Notification';
import { useSelector } from 'react-redux';

const Transfer = () => {
  const history = useHistory();
  const location = useLocation();
  const [fail, setFail] = useState(false);

  const lastDistrict = useSelector((state) => state.location.district);
  const [district, setDistrict] = useState('Select');
  const districtList = [district];

  const lastHospital = useSelector((state) => state.location.hospital);
  const [hospital, setHospital] = useState('Select');
  const hospitalList = [hospital];

  const lastWard = useSelector((state) => state.location.ward);
  const [ward, setWard] = useState('Select');
  const wardList = useSelector((state) => state.location.wardList);

  useEffect(async () => {
    setDistrict(lastDistrict);
    setHospital(lastHospital);
    setWard(lastWard);
  }, []);

  const transferClicked = async () => {
    const wardId = wardList.filter((w) => w.lcName === ward)[0].lcID;
    const patientReq = {
      ptID: location.state.patient.ptID,
      ptFirstName: location.state.patient.ptFirstName,
      ptLastName: location.state.patient.ptLastName,
      ptMRN: location.state.patient.ptMRN,
      ptArchived: false,
      lcID: wardId,
    };
    try {
      const updateResponse = await API.graphql(
        graphqlOperation(updatePatient, patientReq),
        { Authorization: getAdminAuthToken() }
      );
      console.log('Update patient success: ', updateResponse);
      history.push({ pathname: '/dashboard', from: 'transfer' });
    } catch (err) {
      setFail(true);
      console.error(err);
      return -1;
    }
  };

  const updateDistrict = (event) => {
    setDistrict(event);
  };
  const updateHospital = (event) => {
    setHospital(event);
  };
  const updateWard = (event) => {
    setWard(event);
  };

  const disableButton = ward === lastWard;

  return (
    <NarrowContainer>
      {fail && (
        <Notification
          warning
          message={'Unable to complete request. Please try again later'}
        />
      )}
      <div className={styles.container}>
        <div>
          <H4>Transfer Ward</H4>
          <P>
            To transfer patient in the system, choose your ward by first
            filtering on your LHD and Hospital
          </P>
          <Dropdown
            label={'Local Health District'}
            selected={district}
            options={districtList}
            selectedHandler={updateDistrict}
            required={true}
          />
          <Dropdown
            label={'Hospital'}
            selected={hospital}
            options={hospitalList}
            selectedHandler={updateHospital}
            required={true}
          />
          <Dropdown
            label={'Ward'}
            selected={ward}
            options={wardList.map((h) => h.lcName)}
            selectedHandler={updateWard}
            required={true}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            style={{ marginRight: '0.25rem' }}
            onClick={transferClicked}
            isDisabled={disableButton}
            disabled={disableButton}>
            Transfer Ward
          </Button>
          <OutlinedButton
            style={{ marginLeft: '0.25rem' }}
            onClick={() => {
              history.goBack();
            }}>
            Cancel
          </OutlinedButton>
        </div>
      </div>
    </NarrowContainer>
  );
};

export default Transfer;

import styles from './MultiInputRow.module.scss';
import React from 'react';
import InputField from '../input/InputField';
import { P } from '../../../styled/components';

const MultiInputRow = ({
  emailHandler,
  phoneHandler,
  nameHandler,
  index,
  email,
  phoneNumber,
  name,
  showLabels,
}) => {
  return (
    <div className={styles.flex}>
      <InputField
        inputHandler={(e) => emailHandler(e, index)}
        label='Email Address'
        value={email}
        placeholder='name@example.com'
        type='email'
        showLabel={showLabels}
      />
      <div className={styles.middle}>
        <P>And/or</P>
      </div>
      <InputField
        style={{ marginRight: '0.3rem' }}
        inputHandler={(e) => phoneHandler(e, index)}
        value={phoneNumber}
        label='Phone Number'
        placeholder='e.g. +61 4XXXXXXXX'
        showLabel={showLabels}
      />
      <InputField
        inputHandler={(e) => nameHandler(e, index)}
        label='Visitor’s Name'
        value={name}
        placeholder='E.g. Barry Smith'
        showLabel={showLabels}
        required
      />
    </div>
  );
};

export default MultiInputRow;

import styles from './VisitorInfoRow.module.scss';
import React from 'react';

const VisitorInfoRow = ({
  email,
  phoneNumber,
  name,
  index,
  editHandler,
  removeHandler,
}) => {
  return (
    <div className={styles.container}>
      <h5>{name}</h5>
      <h5 className={styles.email}>{email}</h5>
      <h5>{phoneNumber}</h5>
      <a className={styles.edit} onClick={(e) => editHandler(e, index)}>
        Edit
      </a>
      <a className={styles.remove} onClick={(e) => removeHandler(e, index)}>
        Remove
      </a>
    </div>
  );
};

export default VisitorInfoRow;

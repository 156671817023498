import styled from 'styled-components';
import COLOURS from './colours';

export const Button = styled.button`
	font-family: 'Montserrat';
	font-weight: 600;
	border-radius: 5px;
	padding: 0 1rem;
	width: 100%;
	height: 3.5rem;
	font-size: 1rem;
	background-color: ${props => props.isDisabled ? COLOURS.BLUE_DISABLED : COLOURS.BLUE || COLOURS.BLUE};
	color: #fff;
	border: 1px solid #fff;
	cursor: pointer;
`;

export const OutlinedButton = styled.button`
	font-family: 'Montserrat';
	font-weight: 600;
	border-radius: 5px;
	padding: 0 1rem;
	width: 100%;
	height: 3.5rem;
	font-size: 1rem;
	color: ${COLOURS.BLUE};
	border: 2px solid ${COLOURS.BLUE};
	background-color: transparent;
	cursor: pointer;
`;

export const Container = styled.div`
	width: 80vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 80vh;
	align-items: flex-start;
	margin: auto;
	padding-bottom: 4rem;
`;

export const NarrowContainer = styled.div`
	width: 40vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 80vh;
	align-items: flex-start;
	margin: auto;
	padding-bottom: 4rem;
`;

export const Input = styled.input`
	width: 100%;
	height: 4rem;
	padding: 1rem;
	border: 1px solid ${COLOURS.GREY};
	border-radius: 0.3rem;
	font-family: Montserrat;
	&:focus {
		outline: none;
		border: 1px solid black
	}
	&:disabled {
		background-color: #f4f4f7;
		color: black;
	}
`;

export const Alert = styled.div`
	min-width: 1.8rem;
	min-height: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: ${props => props.invert ? COLOURS.RED_WARNING : 'white'};
	background: ${props => props.invert ? 'white' : COLOURS.RED_WARNING};
`;

export const Success = styled.div`
	min-width: 1.8rem;
	min-height: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: ${props => props.invert ? COLOURS.GREEN : 'white'};
	background: ${props => props.invert ? 'white' : COLOURS.GREEN};
`;

export const Info = styled.div`
	width: 1.8rem;
	height: 1.8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1.2px solid ${COLOURS.BLUE};
	color: ${COLOURS.BLUE};
`;


export const P = styled.p`
	color: ${COLOURS.DARKGREY};
	font-family: Montserrat;
`;

export const H4 = styled.div`
	font-size: 1.4rem;
	font-family: 'Montserrat';
	font-weight: 600;
`;

export const H5 = styled.div`
	font-size: 1.1rem;
	font-weight: 700;
	font-family: Montserrat;
`;

export const Link = styled.a`
	font-family: Montserrat;
	color: ${COLOURS.BLUE};
	font-style: ${props => props.italic ? 'italic' : 'none'};
	font-weight: ${props => props.bold ? '700' : '400'};
	cursor: pointer;
`;

export const CallButton = styled.button`
	width: ${props => props.width ? props.width : '8rem'};
	height: ${props => props.height ? props.height : '8rem'};
	background: ${props => props.green ? COLOURS.GREEN : props.black ? 'black' : COLOURS.RED_WARNING};
	border: ${props => props.border ? '1px solid #555555' : 'none'};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	position: relative;
`;
import styles from './Grid.module.scss';
import React from 'react';
import { P } from '../../../styled/components';

const StatusCell = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return <P>{cellValue ? 'Archived' : 'Active'}</P>;
};

export default StatusCell;

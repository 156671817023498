import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import { Info, Link, OutlinedButton, P } from '../../../styled/components.js';
import { useLocation, useHistory } from 'react-router-dom';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAdminToken } from '../../../store/actions/app';

const Header = () => {
  const history = useHistory();
  const url = useLocation();
  const district = useSelector((state) => state.location.district);
  const hospital = useSelector((state) => state.location.hospital);
  const ward = useSelector((state) => state.location.ward);
  const showLocation =
    url.pathname !== '/location' &&
    url.pathname !== '/' &&
    url.pathname !== '/login';

  const [showHeader, setShowHeader] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const blackList = ['/call', '/calling', '/waitingroom', '/incall'];
    setShowHeader(!blackList.includes(url.pathname));
  }, [url]);

  const redirect = () => {
    history.push('/location');
  };

  const logout = () => {
    dispatch(setAdminToken(''));
    history.push('/');
  };

  const helpClicked = () => {
    window.open(
      'https://nswhealth.sharepoint.com/_forms/default.aspx',
      '_blank'
    );
  };

  if (showHeader) {
    return (
      <div style={{ marginBottom: '2rem' }}>
        <div className={styles.container}>
          <img src='/logo.png' className={styles.logo} />
          {showLocation && (
            <div className={styles.info}>
              <P>
                Location: {district} | {hospital} | {ward}
              </P>
              <Link italic onClick={redirect}>
                Not the right place? Choose another location
              </Link>
            </div>
          )}

          <div className={styles.flex}>
            <Link className={styles.link} onClick={helpClicked}>
              Help
            </Link>
            <Info>?</Info>
            {showLocation && (
              <OutlinedButton
                style={{ width: '7rem', marginLeft: '1rem', height: '3rem' }}
                onClick={logout}>
                Sign Out
              </OutlinedButton>
            )}
          </div>
        </div>
        {showLocation && <Breadcrumbs />}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Header;

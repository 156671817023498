/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      dvID
      ptID
      dvLastContact
      ssDialString
      dvPin
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const onCreateLocationType = /* GraphQL */ `
  subscription OnCreateLocationType {
    onCreateLocationType {
      ltID
      ltName
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient {
    onCreatePatient {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptAutoAnswer
      ptArchived
      lcID
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession {
    onCreateSession {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
    }
  }
`;
export const onDeviceAssign = /* GraphQL */ `
  subscription OnDeviceAssign {
    onDeviceAssign {
      statusCode
      body {
        success
        message
        jwt
        deviceId
        ptID
      }
    }
  }
`;
export const onDeviceUnassign = /* GraphQL */ `
  subscription OnDeviceUnassign {
    onDeviceUnassign {
      ptID
    }
  }
`;
export const onPatientCallChange = /* GraphQL */ `
  subscription OnPatientCallChange {
    onPatientCallChange {
      ptID
      ptCallCount
      pt24CallCount
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession {
    onUpdateSession {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
    }
  }
`;

const COLOURS = {
    BLUE: '#002664',
    GREEN: '#31c54d',
    BLUE_DISABLED: '#7f92b0',
    RED_WARNING: '#c53140',
    GREY: '#a0a0a0',
    DARKGREY: '#333333' 
   
};

export default COLOURS;

import styles from './Accordion.module.scss';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Dropdown as DD } from 'reactstrap';
import React, { useState } from 'react';

const Accordion = ({ title, copy }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div
      className={styles.container}
      style={dropdownOpen ? { marginBottom: '15rem' } : {}}>
      <DD className={styles.dropdown} isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{
            width: '100%',
            textAlign: 'left',
            position: 'relative',
            backgroundColor: '#f4f4f7',
            fontWeight: 600,
            color: '#333333',
            border: 'none',
            lineHeight: 2.5,
            paddingBottom: '3px',
          }}>
          {title}
          <img
            className={styles.arrow}
            src={dropdownOpen ? 'arrow-up.png' : 'arrow-down.png'}
          />
        </DropdownToggle>
        <DropdownMenu
          style={{
            width: '100%',
            maxWidth: '100%',
            backgroundColor: 'white',
            border: 'none',
            borderLeft: '4px #002664',
            height: 'auto',
            overflowY: 'none',
            overflowX: 'hidden',
          }}>
          <DropdownItem
            style={{
              backgroundColor: 'white',
              lineHeight: 2,
              height: 'auto',
              whiteSpace: 'pre-line',
            }}>
            {copy}
          </DropdownItem>
        </DropdownMenu>
      </DD>
    </div>
  );
};

export default Accordion;

import React, { useState, useEffect } from 'react';
import styles from './ConfirmArchive.module.scss';
import { Button, NarrowContainer, P, H4 } from '../../styled/components';
import { useHistory, useLocation } from 'react-router-dom';
import Notification from '../../components/shared/notifications/Notification';
import { updatePatient } from '../../graphql/mutations';
import { getAdminAuthToken } from '../../Shared';
import { API, graphqlOperation } from 'aws-amplify';
import { useSelector } from 'react-redux';

const ConfirmArchive = () => {
  const history = useHistory();
  const location = useLocation();
  const [patientName, setPatientName] = useState('');
  const [fail, setFail] = useState(false);
  const wardId = useSelector((state) => state.location.wardId);

  useEffect(() => {
    console.log(location.state);
    setPatientName(
      location.state.patient.ptFirstName +
        ' ' +
        location.state.patient.ptLastName
    );
  }, [location]);

  const updatePatientReq = async (patientReq) => {
    try {
      const updateResponse = await API.graphql(
        graphqlOperation(updatePatient, patientReq),
        { Authorization: getAdminAuthToken() }
      );
      console.log('Update patient success: ', updateResponse);
      return 1;
    } catch (err) {
      console.error(err);
      return -1;
    }
  };

  const unarchive = async () => {
    const fName = patientName.split(' ')[0] || '';
    const lName = patientName.split(' ')[1] || '';

    const patientReq = {
      ptID: location.state.patient.ptID,
      ptFirstName: fName,
      ptLastName: lName,
      ptMRN: location.state.patient.ptMRN,
      ptArchived: false,
      lcID: wardId,
    };
    const pid = await updatePatientReq(patientReq);
    if (pid > 0) history.push('/dashboard');
    else {
      setFail(true);
    }
  };

  return (
    <NarrowContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
      {fail && (
        <Notification
          warning
          message={'Sorry, unarchive failed. Please try again later'}
        />
      )}
      <H4 style={{ textAlign: 'center' }}>
        {patientName} Patient Visitation file is archived
      </H4>
      <div className={styles.btns}>
        <Button
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            history.push('/dashboard');
          }}>
          Back to Dashboard
        </Button>
        <Button
          style={{
            backgroundColor: '#c53040',
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
          }}
          onClick={unarchive}>
          Unarchive Patient Visitation File
        </Button>
      </div>
    </NarrowContainer>
  );
};

export default ConfirmArchive;

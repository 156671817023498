import React, { useState, useEffect } from 'react';
import styles from './Login.module.scss';
import { API, graphqlOperation } from 'aws-amplify';
import { doLogin } from '../../graphql/mutations';
import { getAdminAuthToken } from '../../Shared';
import { useHistory } from 'react-router-dom';
import InputField from '../../components/shared/input/InputField';
import { Button, NarrowContainer, P, H4 } from '../../styled/components';
import FadeLoader from 'react-spinners/FadeLoader';
import { css } from '@emotion/react';
import COLOURS from '../../styled/colours';
import Notification from '../../components/shared/notifications/Notification';
import { useDispatch } from 'react-redux';
import { setAdminToken } from '../../store/actions/app';

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState('pexip');
  const [password, setPassword] = useState('PexP45522');
  const [loading, setLoading] = useState(false);
  const [fail, setFail] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAdminToken(''));
  }, []);

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  const override = css`
    display: absolute;
    left: 50%;
    top: 50%;
  `;

  const loginClicked = async () => {
    setLoading(true);

    try {
      var loginParameters = {
        username: username,
        password: password,
        domain: 'visitme.local',
      };
      const loginResponse = await API.graphql(
        graphqlOperation(doLogin, loginParameters),
        { Authorization: getAdminAuthToken() }
      );
      setLoading(false);
      if (loginResponse.data.doLogin.body.success === true) {
        dispatch(setAdminToken(loginResponse.data.doLogin.body.jwt));
        setFail(false);
        history.push('/location');
      } else {
        setFail(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <NarrowContainer>
      {fail && (
        <Notification warning message={'Incorrect username or password'} />
      )}
      <div className={styles.loginContainer}>
        <div className={styles.loginGrid}>
          <H4 className={styles.mrgnSml}>Welcome to Visitation</H4>
          <P className={styles.mrgn}>Please log in to begin</P>
          <div className={styles.mrgn}>
            <InputField
              value={username}
              inputHandler={usernameChange}
              label='StaffLink ID'
              placeholder='StaffLink ID'
              required
            />
          </div>
          <InputField
            value={password}
            type={'password'}
            inputHandler={passwordChange}
            label='Password'
            placeholder='password'
            required
          />
          <FadeLoader color={COLOURS.BLUE} loading={loading} css={override} />
        </div>
        <Button
          style={{ position: 'absolute', bottom: '2rem' }}
          onClick={loginClicked}
          isDisabled={username === '' || password === ''}>
          Log in
        </Button>
      </div>
    </NarrowContainer>
  );
};

export default Login;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($dvID: Int, $ptID: Int) {
    getDevice(dvID: $dvID, ptID: $ptID) {
      dvID
      ptID
      dvLastContact
      ssDialString
      dvPin
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices($ptID: Int) {
    listDevices(ptID: $ptID) {
      dvID
      ptID
      dvLastContact
      ssDialString
      dvPin
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($lcID: Int!) {
    getLocation(lcID: $lcID) {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations {
    listLocations {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const getLocationType = /* GraphQL */ `
  query GetLocationType($ltID: Int!) {
    getLocationType(ltID: $ltID) {
      ltID
      ltName
    }
  }
`;
export const listLocationTypes = /* GraphQL */ `
  query ListLocationTypes {
    listLocationTypes {
      ltID
      ltName
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($ptID: Int!) {
    getPatient(ptID: $ptID) {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptAutoAnswer
      ptArchived
      lcID
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients($ward: Int) {
    listPatients(ward: $ward) {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptArchived
      ptAutoAnswer
      lcID
      patientDashboard {
        ptInCall
        ptDeviceAssigned
        pt24CallCount
      }
      patientVisitingHours {
        ssStart
        ssEnd
        ssIndefinite
      }
      patientContacts {
        pcID
        pcName
        pcEmail
        pcPhone
      }
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($ssID: Int, $ptID: Int, $ssDialString: String) {
    getSession(ssID: $ssID, ptID: $ptID, ssDialString: $ssDialString) {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
      patient {
        ptID
        ptFirstName
        ptLastName
        ptMRN
        ptAutoAnswer
        ptArchived
        lcID
      }
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions {
    listSessions {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
    }
  }
`;
export const getPatientContacts = /* GraphQL */ `
  query GetPatientContacts($ptID: Int) {
    getPatientContacts(ptID: $ptID) {
      pcID
      ptID
      pcName
      pcEmail
      pcPhone
      pcArchived
    }
  }
`;
export const listLocalHealthDistricts = /* GraphQL */ `
  query ListLocalHealthDistricts {
    listLocalHealthDistricts {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const listHospitals = /* GraphQL */ `
  query ListHospitals($lcParent: Int) {
    listHospitals(lcParent: $lcParent) {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const listWards = /* GraphQL */ `
  query ListWards($lcParent: Int) {
    listWards(lcParent: $lcParent) {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const getPatientCall = /* GraphQL */ `
  query GetPatientCall($clUUID: String, $clCallId: String) {
    getPatientCall(clUUID: $clUUID, clCallId: $clCallId) {
      clID
      ssID
      clUUID
      clCallId
      clRole
      clDisplayName
      clStatus
      clLastUpdate
      pcArchived
    }
  }
`;
export const getPatientCallCount = /* GraphQL */ `
  query GetPatientCallCount {
    getPatientCallCount {
      count
    }
  }
`;
export const getContactLog = /* GraphQL */ `
  query GetContactLog {
    getContactLog {
      clID
      ctID
      pcName
      pcEmail
      pcPhone
      ptFirstName
      ptLastName
      pcArchived
      clSMSSent
      clEmailSent
      ssDialString
      ssStart
      ssEnd
      ssIndefinite
    }
  }
`;
export const getContactTemplate = /* GraphQL */ `
  query GetContactTemplate {
    getContactTemplate {
      ctID
      ctSMSBody
      ctEmailBody
    }
  }
`;
export const cameronTest = /* GraphQL */ `
  query CameronTest($ward: Int) {
    cameronTest(ward: $ward) {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptArchived
      ptAutoAnswer
      lcID
      patientDashboard {
        ptInCall
        ptDeviceAssigned
        pt24CallCount
      }
      patientVisitingHours {
        ssStart
        ssEnd
        ssIndefinite
      }
      patientContacts {
        pcID
        pcName
        pcEmail
        pcPhone
      }
    }
  }
`;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify, { Auth } from 'aws-amplify';

import { Provider } from 'react-redux';
import { rootReducer } from './store/store';
import { createStore } from 'redux';

const store = createStore(rootReducer);
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'ap-southeast-2:adcbf872-6298-4971-8dbd-aff1d05c7cd6',

    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',
  },
  aws_project_region: 'ap-southeast-2',
  aws_appsync_graphqlEndpoint:
    'https://toq6fdwro5ddfc26v2ydvzoima.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  aws_appsync_apiKey: 'da2-wqhgkusc6feazeei7ixklmcgem',
});

// You can get the current config object
const currentConfig = Auth.configure();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

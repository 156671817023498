import styles from './Grid.module.scss';
import React from 'react';

const EditGridButton = (props) => {
  return (
    <button
      className={styles.editBtn}
      onClick={() => {
        props.clicked(props);
      }}>
      Edit/Manage
    </button>
  );
};

export default EditGridButton;

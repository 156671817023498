import styles from './Grid.module.scss';
import React, { useState, useRef, useEffect } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import EditGridButton from './EditGridButton';
import CheckGrid from './CheckGrid';
import StatusCell from './StatusCell';
import { useHistory } from 'react-router-dom';

const Grid = ({ rowData, sort }) => {
  const history = useHistory();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    setSort();
  }, [sort]);

  useEffect(() => {
    if (gridColumnApi && gridApi) {
      gridApi.setRowData(rowData);
    }
  }, [rowData]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    setSort();
  };
  const frameworkComponents = {
    editBtnCellRenderer: EditGridButton,
    checkCellRenderer: CheckGrid,
    statusCellRenderer: StatusCell,
  };

  const numberSort = (num1, num2) => {
    return num1 - num2;
  };

  const columnDefs = [
    {
      headerName: 'Status',
      field: 'status',
      type: 'leftAligned',
      cellClass: 'no-focus',
      cellRenderer: 'statusCellRenderer',
    },
    {
      headerName: 'MRN',
      field: 'MRN',
      type: 'leftAligned',
      cellClass: 'no-focus',
      comparator: numberSort,
    },
    {
      headerName: 'Patient Name',
      field: 'name',
      type: 'leftAligned',
      cellClass: 'no-focus',
    },
    {
      headerName: 'Device assigned',
      field: 'hasDevice',
      cellClass: 'grid-cell-centered',
      headerClass: 'header-cell-centered',
      cellRenderer: 'checkCellRenderer',
    },
    {
      headerName: 'Number of calls in the last 24hrs',
      field: 'numCalls',
      cellClass: 'grid-cell-centered',
    },
    {
      headerName: 'In a call',
      field: 'inCall',
      cellClass: 'grid-cell-centered',
      headerClass: 'header-cell-centered',
      cellRenderer: 'checkCellRenderer',
    },
    {
      headerName: 'Visiting hours',
      field: 'hours',
      cellClass: 'grid-cell-centered',
      headerClass: 'header-cell-centered',
    },
    {
      field: 'manage',
      cellRenderer: 'editBtnCellRenderer',
      headerName: 'Manage patient',
      cellClass: 'grid-cell-centered',
      headerClass: 'header-cell-centered',
      cellRendererParams: {
        clicked: (event) => {
          history.push({
            pathname: '/managepatient',
            state: { patient: event.data.patient, session: event.data.session },
          });
        },
      },
      minWidth: 150,
    },
  ];

  const setSort = () => {
    if (gridColumnApi && gridApi) {
      switch (sort) {
        case 'Name A-Z':
          sortByNameAsc();
          break;
        case 'Name Z-A':
          sortByNameDesc();
          break;
        case 'MRN Ascending':
          sortByMRNAsc();
          break;
        case 'MRN Descending':
          sortByMRNDesc();
          break;
      }
    }
  };

  const sortByMRNAsc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'MRN',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  };

  const sortByMRNDesc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'MRN',
          sort: 'desc',
        },
      ],
      defaultState: { sort: null },
    });
  };

  const sortByNameAsc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'name',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  };

  const sortByNameDesc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'name',
          sort: 'desc',
        },
      ],
      defaultState: { sort: null },
    });
  };

  return (
    <div className='ag-theme-material' style={{ width: '100%', height: 427 }}>
      <AgGridReact
        rowMultiSelectWithClick={false}
        suppressRowClickSelection={true}
        pagination={true}
        rowHeight={45}
        paginationPageSize={7}
        rowData={rowData}
        gridRef={gridRef}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}></AgGridReact>
    </div>
  );
};

export default Grid;

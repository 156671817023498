import styles from './Modal.module.scss';
import React from 'react';
import { P, H4, Button, OutlinedButton } from '../../../styled/components';

const Modal = ({
  buttonLabel1,
  buttonLabel2,
  handleButton1,
  handleButton2,
  title,
  copy,
  contentHTML,
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <H4 className={styles.font}>{title}</H4>
        {copy && <P style={{ marginBottom: 0 }}>{copy}</P>}
        {contentHTML}
        <div className={styles.flex}>
          {buttonLabel1 && (
            <Button
              style={{ backgroundColor: '#c53040' }}
              onClick={handleButton1}>
              {buttonLabel1}
            </Button>
          )}
          {buttonLabel2 && (
            <OutlinedButton
              style={{ marginLeft: '0.5rem', width: '30%' }}
              onClick={handleButton2}>
              {buttonLabel2}
            </OutlinedButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { Link, P } from '../../../styled/components.js';
import { useLocation, useHistory } from 'react-router-dom';

const Breadcrumbs = () => {
  const history = useHistory();
  const url = useLocation();
  const newPatientPage = url.pathname === '/newpatient';
  const transferPage = url.pathname === '/transfer';
  const managePage = url.pathname === '/managepatient';
  const confirmPage = url.pathname === '/confirm';

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <div className={styles.container}>
      {(newPatientPage || transferPage || managePage || confirmPage) && (
        <div className={styles.breadcrumbs}>
          {!transferPage && (
            <Link onClick={() => redirect('/dashboard')}>
              Visitation Dashboard
            </Link>
          )}
          {transferPage && (
            <Link onClick={() => history.goBack()}>Manage Patient</Link>
          )}
          <img src='arrow-up.png' className={styles.arrow} />
          {newPatientPage && <P>Create New Patient </P>}
          {(managePage || confirmPage) && <P>Manage Patient </P>}
          {transferPage && <P>Transfer Ward </P>}
        </div>
      )}
    </div>
  );
};

export default Breadcrumbs;

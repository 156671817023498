import React, { useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { refreshToken } from './graphql/mutations';
import { getAdminAuthToken } from './Shared';
import Login from './pages/login/Login';
import Header from './components/shared/header/Header';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LocationSelect from './pages/locationSelect/LocationSelect';
import Transfer from './pages/transfer/Transfer';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewPatient from './pages/newPatient/NewPatient';
import ManagePatient from './pages/managePatient/ManagePatient';
import Dashboard from './pages/dashboard/Dashboard';
import ConfirmArchive from './pages/confirmArchive/ConfirmArchive';
import { useDispatch } from 'react-redux';
import { setAdminToken } from './store/actions/app';

function App() {
  const dispatch = useDispatch();
  const tokenRefresherRaf = useRef(null);

  useEffect(async () => {
    function cancelAllAnimationFrames() {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      var id = window.requestAnimationFrame(() => {});
      while (id--) {
        window.cancelAnimationFrame(id);
      }
    }
    cancelAllAnimationFrames();

    const fifteenMins = 900000;
    tokenRefresherRaf.current = tokenRefresher(
      Date.now(),
      fifteenMins,
      tokenRefresherRaf
    );
    return () => cancelAnimationFrame(tokenRefresherRaf.current);
  }, []);

  const tokenRefresher = async (start, refreshInterval, ref) => {
    if (start + refreshInterval < Date.now()) {
      await getToken();
      start = Date.now();
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    ref.current = requestAnimationFrame(() =>
      tokenRefresher(start, refreshInterval, ref)
    );
  };

  const getToken = async () => {
    try {
      const authResponse = await API.graphql(graphqlOperation(refreshToken), {
        Authorization: getAdminAuthToken(),
      });
      if (authResponse.data.refreshToken.statusCode !== 200)
        window.location = '/';
      dispatch(setAdminToken(authResponse.data.refreshToken.body.jwt));
    } catch (err) {
      console.error(err);
      window.location = '/';
    }
  };

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/location'>
          <LocationSelect />
        </Route>
        <Route path='/transfer'>
          <Transfer />
        </Route>
        <Route path='/newpatient'>
          <NewPatient />
        </Route>
        <Route path='/managepatient'>
          <ManagePatient />
        </Route>
        <Route path='/confirm'>
          <ConfirmArchive />
        </Route>
        <Route path='/dashboard'>
          <Dashboard />
        </Route>
        <Route path='/'>
          <Login />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

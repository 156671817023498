export const getAdminAuthToken = () => {
  var token = 'admin-'; // If this is a null or '' the request will fail=

  if (localStorage.getItem('app')) {
    token = `admin-${JSON.parse(localStorage.getItem('app')).adminToken}`;
  }

  return token;
};

export const makeTimes = () => {
  let increment = 30;
  let times = [];
  let ap = ['am', 'pm'];
  let startTime = 0;

  for (let i = 0; startTime < 24 * 60; i++) {
    let hh = Math.floor(startTime / 60);
    let mm = startTime % 60;
    let time =
      parseInt(('0' + (hh % 12)).slice(-2)) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)];
    if (hh === 0 || hh === 12) time = time.replace('0:', '12:');
    times[i] = time;
    startTime += increment;
  }
  return times;
};

export const dateToHourString = (date) => {
  let time = date.getHours();
  if (time === 12) time += ':00pm';
  else if (time > 12) {
    time -= 12;
    time += ':00pm';
  } else if (time === 0) time = '12:00am';
  else {
    time += ':00am';
  }
  return time;
};

export const unixToString = (date) => {
  var time = date.getHours();
  let mins = ('0' + date.getMinutes()).slice(-2);
  if (time === 0) time = '12:' + mins + 'am';
  else if (time < 12) time += ':' + mins + 'am';
  else if (time === 12) time += ':' + mins + 'pm';
  else {
    time -= 12;
    time += ':' + mins + 'pm';
  }
  return time;
};

export const getTimestampToTheHour = (time) => {
  if (time === 'Indefinite') {
    return null;
  } else {
    let hours = parseInt(time.match(/^(\d+)/)[0]);
    let mins = parseInt(time.match(/:(\d+)/)[1]);
    let AMPM = time.match(/[a-zA-Z]+/)[0];
    if (hours === 12) hours = 0;
    if (AMPM.toLowerCase() === 'pm') hours += 12;

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins);
    date.setSeconds(0);
    return Math.floor(date / 1000);
  }
};

import styles from './Grid.module.scss';
import React from 'react';

const CheckGrid = (props) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  return <img src={cellValue ? '/checkmark.png' : '/x.png'} />;
};

export default CheckGrid;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice {
    createDevice {
      dvID
      ptID
      dvLastContact
      ssDialString
      dvPin
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($ptID: Int, $dvLastContact: Int) {
    updateDevice(ptID: $ptID, dvLastContact: $dvLastContact) {
      dvID
      ptID
      dvLastContact
      ssDialString
      dvPin
    }
  }
`;
export const registerDevice = /* GraphQL */ `
  mutation RegisterDevice($jwt: String) {
    registerDevice(jwt: $jwt) {
      statusCode
      body {
        success
        deviceId
        jwt
      }
    }
  }
`;
export const assignDevice = /* GraphQL */ `
  mutation AssignDevice($jwt: String!, $patientId: Int!) {
    assignDevice(jwt: $jwt, patientId: $patientId) {
      statusCode
      body {
        success
        message
        jwt
        deviceId
        ptID
      }
    }
  }
`;
export const unassignDevices = /* GraphQL */ `
  mutation UnassignDevices($ptID: Int!) {
    unassignDevices(ptID: $ptID) {
      ptID
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($createLocationInput: CreateLocationInput!) {
    createLocation(createLocationInput: $createLocationInput) {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($updateLocationInput: UpdateLocationInput!) {
    updateLocation(updateLocationInput: $updateLocationInput) {
      lcID
      ltID
      lcName
      lcParent
    }
  }
`;
export const createLocationType = /* GraphQL */ `
  mutation CreateLocationType(
    $createLocationTypeInput: CreateLocationTypeInput!
  ) {
    createLocationType(createLocationTypeInput: $createLocationTypeInput) {
      ltID
      ltName
    }
  }
`;
export const updateLocationType = /* GraphQL */ `
  mutation UpdateLocationType(
    $updateLocationTypeInput: UpdateLocationTypeInput!
  ) {
    updateLocationType(updateLocationTypeInput: $updateLocationTypeInput) {
      ltID
      ltName
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $ptFirstName: String!
    $ptLastName: String!
    $ptMRN: String!
    $ptArchived: Boolean
    $ptAutoAnswer: Boolean
    $lcID: Int!
  ) {
    createPatient(
      ptFirstName: $ptFirstName
      ptLastName: $ptLastName
      ptMRN: $ptMRN
      ptArchived: $ptArchived
      ptAutoAnswer: $ptAutoAnswer
      lcID: $lcID
    ) {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptAutoAnswer
      ptArchived
      lcID
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $ptID: Int!
    $ptFirstName: String
    $ptLastName: String
    $ptMRN: String
    $ptArchived: Boolean
    $ptAutoAnswer: Boolean
    $lcID: Int
  ) {
    updatePatient(
      ptID: $ptID
      ptFirstName: $ptFirstName
      ptLastName: $ptLastName
      ptMRN: $ptMRN
      ptArchived: $ptArchived
      ptAutoAnswer: $ptAutoAnswer
      lcID: $lcID
    ) {
      ptID
      ptFirstName
      ptLastName
      ptMRN
      ptAutoAnswer
      ptArchived
      lcID
    }
  }
`;
export const createPatientContact = /* GraphQL */ `
  mutation CreatePatientContact(
    $ptID: Int!
    $pcName: String
    $pcEmail: String
    $pcPhone: String
  ) {
    createPatientContact(
      ptID: $ptID
      pcName: $pcName
      pcEmail: $pcEmail
      pcPhone: $pcPhone
    ) {
      pcID
      ptID
      pcName
      pcEmail
      pcPhone
      pcArchived
    }
  }
`;
export const updatePatientContact = /* GraphQL */ `
  mutation UpdatePatientContact(
    $pcID: Int!
    $pcName: String
    $pcEmail: String
    $pcPhone: String
    $pcArchived: Boolean
  ) {
    updatePatientContact(
      pcID: $pcID
      pcName: $pcName
      pcEmail: $pcEmail
      pcPhone: $pcPhone
      pcArchived: $pcArchived
    ) {
      pcID
      ptID
      pcName
      pcEmail
      pcPhone
      pcArchived
    }
  }
`;
export const updatePatientContacts = /* GraphQL */ `
  mutation UpdatePatientContacts(
    $patientContacts: [UpdatePatientContactsInput]
  ) {
    updatePatientContacts(patientContacts: $patientContacts) {
      pcID
      ptID
      pcName
      pcEmail
      pcPhone
      pcArchived
    }
  }
`;
export const createPatientCall = /* GraphQL */ `
  mutation CreatePatientCall(
    $ssID: Int!
    $clUUID: String
    $clCallId: String
    $clRole: String
    $clDisplayName: String
    $clStatus: String
    $clArchived: Boolean
  ) {
    createPatientCall(
      ssID: $ssID
      clUUID: $clUUID
      clCallId: $clCallId
      clRole: $clRole
      clDisplayName: $clDisplayName
      clStatus: $clStatus
      clArchived: $clArchived
    ) {
      ptID
      ptCallCount
      pt24CallCount
    }
  }
`;
export const updatePatientCall = /* GraphQL */ `
  mutation UpdatePatientCall(
    $clID: Int!
    $clRole: String
    $clDisplayName: String
    $clStatus: String
    $clArchived: Boolean
  ) {
    updatePatientCall(
      clID: $clID
      clRole: $clRole
      clDisplayName: $clDisplayName
      clStatus: $clStatus
      clArchived: $clArchived
    ) {
      ptID
      ptCallCount
      pt24CallCount
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $ptID: Int!
    $ssStart: Int!
    $ssEnd: Int!
    $ssIndefinite: Boolean!
    $ssArchived: Boolean!
  ) {
    createSession(
      ptID: $ptID
      ssStart: $ssStart
      ssEnd: $ssEnd
      ssIndefinite: $ssIndefinite
      ssArchived: $ssArchived
    ) {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $ssID: Int
    $ptID: Int
    $ssStart: Int
    $ssEnd: Int
    $ssIndefinite: Boolean
    $ssArchived: Boolean
  ) {
    updateSession(
      ssID: $ssID
      ptID: $ptID
      ssStart: $ssStart
      ssEnd: $ssEnd
      ssIndefinite: $ssIndefinite
      ssArchived: $ssArchived
    ) {
      ssID
      ptID
      ssStart
      ssEnd
      ssIndefinite
      ssArchived
      ssDialString
    }
  }
`;
export const doLogin = /* GraphQL */ `
  mutation DoLogin($username: String!, $password: String!, $domain: String!) {
    doLogin(username: $username, password: $password, domain: $domain) {
      statusCode
      body {
        success
        jwt
        message
      }
    }
  }
`;
export const refreshToken = /* GraphQL */ `
  mutation RefreshToken {
    refreshToken {
      statusCode
      body {
        success
        jwt
        message
      }
    }
  }
`;
export const updateContactLog = /* GraphQL */ `
  mutation UpdateContactLog(
    $clID: Int!
    $clSMSSent: Boolean
    $clEmailSent: Boolean
  ) {
    updateContactLog(
      clID: $clID
      clSMSSent: $clSMSSent
      clEmailSent: $clEmailSent
    ) {
      clID
      clSMSSent
      clEmailSent
    }
  }
`;

import React, { useState, useEffect } from 'react';
import styles from './Dashboard.module.scss';
import { Button, Container, H4 } from '../../styled/components';
import { useHistory, useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getAdminAuthToken, unixToString } from '../../Shared';
import { listPatients } from '../../graphql/queries';
import Accordion from '../../components/shared/accordion/Accordion';
import Search from '../../components/shared/search/Search';
import Grid from '../../components/shared/grid/Grid';
import Notification from '../../components/shared/notifications/Notification';
import Dropdown from '../../components/shared/dropdown/Dropdown';
import * as subscriptions from '../../graphql/subscriptions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAdminToken } from '../../store/actions/app';
import { refreshToken } from '../../graphql/mutations';

const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const [patientList, setPatientList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [filteredRowData, setFilteredRowData] = useState([]);
  const [fail, setFail] = useState(false);
  const [sort, setSort] = useState('MRN Ascending');
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const wardId = useSelector((state) => state.location.wardId);

  const sortOptions = [
    'Name A-Z',
    'Name Z-A',
    'MRN Ascending',
    'MRN Descending',
  ];
  const dispatch = useDispatch();

  useEffect(async () => {
    await getToken();
    await fetchPatients();
    doSubscriptions();
    if (patientList.length) createRowData();
  }, []);

  useEffect(() => {
    if (patientList.length) {
      createRowData();
    }
  }, [patientList]);

  const getToken = async () => {
    try {
      const authResponse = await API.graphql(graphqlOperation(refreshToken), {
        Authorization: getAdminAuthToken(),
      });
      if (authResponse.data.refreshToken.statusCode !== 200)
        window.location = '/';
      dispatch(setAdminToken(authResponse.data.refreshToken.body.jwt));
    } catch (err) {
      console.error(err);
      window.location = '/';
    }
  };

  useEffect(() => {
    searchChange(searchInput);
  }, [rowData]);

  useEffect(() => {
    if (
      location?.from === 'newPatient' ||
      location?.from === 'transfer' ||
      location?.from === 'managepatient'
    ) {
      const newPatient =
        'New Patient has been added to the Visitation Dashboard. Patient device ready to be assigned.';
      const transfer = 'Patient transfer completed.';
      const manage = 'Visitation Patient file has been updated.';
      setNotificationMessage(
        location?.from === 'newPatient'
          ? newPatient
          : location?.from === 'transfer'
          ? transfer
          : manage
      );
      setNotification(true);
    }
    const timeout = setTimeout(() => {
      setNotification(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, [location, history]);

  const fetchPatients = async () => {
    try {
      const patientData = await API.graphql(
        graphqlOperation(listPatients, { ward: wardId }),
        { Authorization: getAdminAuthToken() }
      );
      setPatientList(patientData.data.listPatients);
    } catch (err) {
      setFail(true);
      console.error(err);
    }
  };

  const doSubscriptions = async () => {
    unassignSub();
    assignSub();
    callSub();
    seshSub();
  };

  const unassignSub = async () => {
    API.graphql(graphqlOperation(subscriptions.onDeviceUnassign), {
      Authorization: getAdminAuthToken(),
    }).subscribe({
      next: ({ provider, value }) => onDeviceUnassign(provider, value),
      error: (error) => console.warn(error),
    });
  };

  const callSub = async () => {
    API.graphql(graphqlOperation(subscriptions.onPatientCallChange), {
      Authorization: getAdminAuthToken(),
    }).subscribe({
      next: ({ provider, value }) => onPatientCallChange(provider, value),
      error: (error) => console.warn(error),
    });
  };

  const assignSub = async () => {
    API.graphql(graphqlOperation(subscriptions.onDeviceAssign), {
      Authorization: getAdminAuthToken(),
    }).subscribe({
      next: ({ provider, value }) => onDeviceAssign(provider, value),
      error: (error) => console.warn(error),
    });
  };

  const seshSub = async () => {
    API.graphql(graphqlOperation(subscriptions.onCreateSession), {
      Authorization: getAdminAuthToken(),
    }).subscribe({
      next: ({ provider, value }) => onSessionChange(provider, value),
      error: (error) => console.warn(error),
    });
  };

  const onDeviceAssign = async (provider, value) => {
    if (value.data.onDeviceAssign.body.ptID)
      updateDeviceAssignedTo(value.data.onDeviceAssign.body.ptID, true);
  };

  const onDeviceUnassign = async (provider, value) => {
    if (value.data.onDeviceUnassign.ptID)
      updateDeviceAssignedTo(value.data.onDeviceUnassign.ptID, false);
  };

  const onPatientCallChange = async (provider, value) => {
    let ptID = value.data.onPatientCallChange.ptID;
    let ptCallCount = value.data.onPatientCallChange.ptCallCount;
    let pt24CallCount = value.data.onPatientCallChange.pt24CallCount;
    setPatientList((prevState) =>
      prevState.map((p) => {
        if (p.ptID === ptID) {
          let newP = { ...p };
          newP.patientDashboard.ptInCall = ptCallCount > 0;
          newP.patientDashboard.pt24CallCount = pt24CallCount;
          return newP;
        } else {
          return p;
        }
      })
    );
  };

  const updateDeviceAssignedTo = async (id, isAssigned) => {
    setPatientList((prevState) =>
      prevState.map((p) => {
        if (
          p.ptID === id &&
          p.patientDashboard.ptDeviceAssigned !== isAssigned
        ) {
          let newP = { ...p };
          newP.patientDashboard.ptDeviceAssigned = isAssigned;
          return newP;
        } else {
          return p;
        }
      })
    );
  };

  const onSessionChange = async (provider, value) => {
    let ptID = value.data.onCreateSession.ptID;
    setPatientList((prevState) =>
      prevState.map((p) => {
        if (p.ptID === ptID) {
          let newP = { ...p };
          newP.patientVisitingHours.ssStart =
            value.data.onCreateSession.ssStart;
          newP.patientVisitingHours.ssEnd = value.data.onCreateSession.ssEnd;
          return newP;
        } else {
          return p;
        }
      })
    );
  };

  const createRowData = () => {
    if (patientList.length) {
      let rowDataList = [];
      patientList.forEach((p) => {
        let row = {};
        row.patient = p;
        row.MRN = p.ptMRN;
        row.name = p.ptLastName + ', ' + p.ptFirstName;
        row.manage = 'Edit/Manage';
        row.status = p.ptArchived;

        row.hours = p.ptArchived
          ? ''
          : getVisitingHours(p.patientVisitingHours);
        row.session = p.patientVisitingHours;

        row.hasDevice = p.patientDashboard?.ptDeviceAssigned;
        row.numCalls = p.patientDashboard?.pt24CallCount;
        row.inCall = p.patientDashboard?.ptInCall;

        rowDataList.push(row);
      });
      setRowData(rowDataList);
      setFilteredRowData(rowDataList);
    }
  };

  const getVisitingHours = (sesh) => {
    let seshDate = new Date(sesh.ssStart * 1000);
    let start = unixToString(seshDate);
    var end = '';
    if (sesh.ssIndefinite) {
      end = 'Indefinite';
    } else {
      seshDate = new Date(sesh.ssEnd * 1000);
      end = unixToString(seshDate);
    }
    return start + ' - ' + end;
  };

  const searchChange = (q) => {
    let query = q.toLowerCase();
    setSearchInput(query);
    if (query === '') setFilteredRowData(rowData);
    const rowDataUpdated = rowData.filter(
      (r) =>
        r.MRN.toString().toLowerCase().includes(query) ||
        r.name.toLowerCase().includes(query)
    );
    setFilteredRowData(rowDataUpdated);
  };

  const updateSort = (event) => {
    setSort(event);
  };

  const howToCopy =
    'Three steps to set up a patient and get started: \n1. Add a new patient to the service by clicking “Create new patient”\n2. Fill in a simple form and add their nominated visitor(s)\n3. Complete the “Assign device” form on the patient’s bedside device. Once that’s set up, nominated visitors can dial into the device via a link that will automatically be sent to them.';

  return (
    <Container>
      {fail && (
        <Notification
          warning
          message={
            'Sorry, patient list could not be fetched. Please try again later'
          }
        />
      )}
      <H4 style={{ marginBottom: '2rem' }}>
        Welcome to Your Visitation Dashboard
      </H4>
      <Accordion title={'How to get started'} copy={howToCopy} />
      {notification && <Notification message={notificationMessage} />}
      <h5 className={styles.header}>Visitation: Patient List</h5>
      <div className={styles.flex}>
        <div style={{ width: '15rem' }}>
          <Button
            onClick={() => {
              history.push('newpatient');
            }}>
            Create new patient
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <Search onChange={searchChange} />
          <div style={{ width: '7rem', marginLeft: '1rem' }}>
            <Dropdown
              className={styles.dd}
              selected={'Sort by'}
              options={sortOptions}
              selectedHandler={updateSort}
            />
          </div>
        </div>
      </div>
      <Grid rowData={filteredRowData} sort={sort} />
    </Container>
  );
};

export default Dashboard;

import styles from './Search.module.scss';
import ReactSearchBox from 'react-search-box';
import React from 'react';

const Search = ({ onChange }) => {
  return (
    <div className={styles.container}>
      <ReactSearchBox
        placeholder='Search patient name or MRN'
        onChange={(value) => onChange(value)}
        inputBorderColor={'#a8acb5'}
      />
      <img src='search.png' className={styles.arrow} />
    </div>
  );
};

export default Search;
